import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"
import Blocks from "components/Blocks"

// eslint-disable-next-line
export const WhatWeDoPage = ({ data, blogPosts, ...props }) => {
  const [toggle, setToggle] = useState(null);
  let handleToggle = (id) => {
      if(toggle===id){
          setToggle(null);
          return false;
      }
     setToggle(id);
  }

  return (
    <Layout {...props} {...data} {...blogPosts}>
      <Container className={styles.wrapper}>
          {data.bannerTitle ? (
             <div>
              <Title className={styles.bannertitle}>{data.title}</Title>
              <p className={`${styles.title} ${styles.titlesmall}`}>
                <span dangerouslySetInnerHTML={{ __html: data.bannerTitle || ""}} />
              </p>
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
              <Link className={styles.bannerbutton} to={`${data.bannerButtonUrl}`}>{data.bannerButtonText}</Link>
            </div>
          ) : 
          <div>
            <Title className={styles.title}>
              <span>{data.title}</span>
            </Title>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
          </div>
           }
        {data.image ? (
          <div className={styles.image}>
            {data.image.extension === "svg" ? (
              <img alt={data.bannerImageAltText ? data.bannerImageAltText : data.name} title={data.name} src={data.image.publicURL} loading="lazy" width="560" height="500"/>
            ) : data.image.childImageSharp ? (
              <Img
                loading="lazy"
                objectFit="contain"
                alt={data.bannerImageAltText ? data.bannerImageAltText : data.name}
                title={data.name}
                fluid={data.image.childImageSharp.fluid}
              />
            ) : null}
          </div>
        ) : null}
      </Container>

      {(data.blocks || []).map((block, i) => {
        const Elem = Blocks[block.type]
        return Elem ? <Elem key={i} {...block} {...blogPosts} /> : null }
      )}

      {data.form && data.portalId ? (
        <div id="contact-form" className={cx({ [styles.grayBackground]: styles.grayBackground, }, {
          [styles.white]: data.formBgColor === 'isWhite',
        })}>
          <Container>
            <div className={styles.titleContainer}>
              <TitleBlock isLarger><span className={styles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || ""}} /></TitleBlock>
            </div>
            <HubspotForm id={data.form} portalId={data.portalId} />
          </Container>
        </div>
      ) : null}

      {data.faqs ? (
        <div className={cx(styles.faqs, { [styles.grayBackground]: styles.grayBackground}, {
          [styles.white]: data.formBgColor === 'isWhite', 
        })}>
          <Container>
            <div className={styles.titleContainer}>
              <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: data.faqsTitle || ""}} /></TitleBlock>
            </div>
            <div className={styles.faqsList}>
              {data.faqs.filter(Boolean).map((item, i) => (
                <div className={styles.faqsItem} key={i} onClick={() => handleToggle(i)}>
                  <h3 className={toggle===i ? styles.active : null}>
                    {item.title}
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: marked(item.text || "")}} className={cx(styles.faqsContent, toggle===i ? styles.show : styles.hide)}/>
                </div>
              ))}
            </div>
          </Container>
        </div>
      ) : null}
    </Layout>
  )
}

// eslint-disable-next-line
export const WhatWeDoPageWrapper = ({ data, ...props }) => (
  <WhatWeDoPage {...props} 
    data={data.markdownRemark.frontmatter}
    blogPosts={data.blogPosts}
  />
)

export default WhatWeDoPageWrapper

export const pageQuery = graphql`
  query WhatWeDoPage($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        id
        bannerTitle
        title
        description
        bannerButtonText
        bannerButtonUrl
        formTitle
        form
        portalId
        category
        formBgColor
        bannerImageAltText
        faqsTitle
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        faqs {
          title
          text
        }
        blocks {
          type
          title
          secondTitle
          thirdTitle
          description
          buttonText
          buttonUrl
          subTitle
          layout
          bgColor
          imagePosition
          imageWidth
          paddingTop
          text
          imageAltText
          secondImageAltText
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          secondImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          bigListList {
            title
            description
          }
          gridWithImageList {
            title
            description
          }
          imageAndListList
          gridWithIconsList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          bigListWithBackgroundsList {
            title
            description
          }
          gridWithImageAndList {
            title
            description
          }
          cards {
            title
            text
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          gridWithLeftedImage {
            title
            text
          }
          gradientedGridList {
            title
            text
          }
          twoCollGridList {
            title
            text
          }
          iconsWithBackgroundsList {
            title
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          listWithImageAndDescription {
            title
            description
          }
          gridWithIconsAndImageList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagesGrid {
            imageAltText
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          partners {
            link
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithImage {
            title
            subtitle
            text
            buttonText
            buttonUrl
            imageAltText
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          textWithCards {
            title
            preTitle
            description
            text
            buttonText
            buttonUrl
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          iconsGridList {
            title
            subtitle
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          tableData {
            title
            description
            tableHead {
              label
            }
            tableRow {
              row {
                value
                icon
              }
            }
            secondTitle
            additionals {
              label
              link
            }
          }
          fullWidthCardsList {
            title
            description
            imageAltText
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          blobIcons {
            title
            link
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          ppcImageWithContent {
            imageAltText
            label
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          textWithImageAndIcons {
            title
            subtitle
            text
            buttonText
            description
            buttonUrl
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          contentWithList {
            secondTitle
            list {
              label
              link
            }
          }
          ppcContentGrid {
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          ppcTestimonial {
            description
            author
            authorPosition
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 150, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          ppcSiteDataList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 150, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { templateKey: { eq: "blog" } },
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            description
            date(formatString: "MMM Do, YYYY")
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
